const ChevrondownSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={6}
    fill="none"
    {...props}
  >
    <path
      fill="#8A91A5"
      d="M1.207 0h9.586a.5.5 0 0 1 .353.854L6.707 5.293a1 1 0 0 1-1.414 0L.853.853A.5.5 0 0 1 1.208 0Z"
    />
  </svg>
)
export { ChevrondownSvg }
