import { forwardRef } from 'react'
import CreatableSelect from 'react-select/creatable'
import classNames from 'classnames'
import ReactSelect from 'react-select'
import capitalize from 'lodash/capitalize'
import DropdownIndicator from './components/dropdown-indicator'
import OptionCustom from './components/option-custom'
import SingleValueTooltip from './components/single-value-tooltip'
import {
  controlBorderColorVar,
  customLabelWrapper,
  selectInputBoard,
  selectInputBoardDisplayVar,
  selectInputTitle,
  selectWrapper,
  selectWrapperDisplayVar,
} from './select.css.ts'
import { ElementError } from '../error/index.tsx'
import { SelectOption, SelectProps } from './types'
import { getSelectStyling } from './react-select-style-overrides'
import { assignInlineVars } from '@vanilla-extract/dynamic'
import { vars } from '/@theme/theme.css.ts'

const Select = forwardRef(
  <T extends SelectOption = SelectOption>(
    props: SelectProps<T>,
    forwardedRef,
  ) => {
    const {
      isCreatable,
      name,
      className,
      disabled,
      caption,
      readOnly,
      value,
      components,
      hideDropdownIndicator = false,
      isMulti = false,
      shouldUsePortal = false,
      useCustomLabel = false,
      isSearchable = false,
      styles,
      isInline,
      errors,
      required,
      ...rest
    } = props

    const wrapperClasses = classNames('pq-select__wrapper', selectWrapper)
    const classes = classNames('pq-select', className)

    const customStyles = getSelectStyling()

    const setErrorClass = () => {
      let errorClass = ''
      if (!value && errors && errors[name]) {
        errorClass = '-error'
      }
      return errorClass
    }

    const boardClasses = classNames(
      'pq-select__input-board',
      selectInputBoard,
      (disabled || readOnly) && '-disabled',
      setErrorClass(),
    )

    const titleClasses = classNames(
      'pq-select__input-title',
      selectInputTitle,
      disabled && '-disabled',
    )

    let selectProps: any = {
      ref: forwardedRef,
      menuPortalTarget:
        shouldUsePortal && typeof window !== 'undefined'
          ? document.body
          : undefined,
      className: classes,
      classNamePrefix: 'pq-select',
      'aria-labelledby': 'aria-label',
      formatCreateLabel: (input: string) => capitalize(input),
      name,
      instanceId: name,
      components: {
        DropdownIndicator:
          !hideDropdownIndicator && !isMulti ? DropdownIndicator : undefined,
        SingleValue: (singleValueProps: any) => {
          if (useCustomLabel) {
            const {
              data: { label },
            } = singleValueProps
            return <div className={customLabelWrapper}>{label}</div>
          }
          return SingleValueTooltip(singleValueProps)
        },
        Option: OptionCustom,
        ...components,
      },
      menuIsOpen: readOnly ? false : undefined,
      keepCursorAtEnd: true,
      isDisabled: disabled,
      readOnly,
      isSearchable,
      value,
      required,
      ...rest,
    }

    if (isCreatable) {
      selectProps = {
        isOverflowed: true,
        styles: {
          ...customStyles,
          dropdownIndicator: (dropdownIndicatorStyles: any) => ({
            ...dropdownIndicatorStyles,
            display: 'none',
          }),
          ...styles,
        },
        ...selectProps,
      }
    } else {
      selectProps = {
        isDisabled: disabled,
        styles: {
          ...customStyles,
          ...styles,
        },
        ...selectProps,
      }
    }

    const renderSelectType = () => {
      if (isCreatable) {
        return <CreatableSelect {...selectProps} />
      }

      return <ReactSelect {...selectProps} />
    }

    return (
      <div
        className={wrapperClasses}
        data-cy="pq-select__wrapper"
        style={assignInlineVars({
          [selectWrapperDisplayVar]: isInline ? 'inline' : 'block',
        })}
      >
        <div
          style={assignInlineVars({
            [selectInputBoardDisplayVar]: isInline ? 'inline-block' : 'block',
            [controlBorderColorVar]: !readOnly ? vars.color.primary.blue : '',
          })}
          className={boardClasses}
          data-cy="form-pq-select"
        >
          {caption && (
            <span
              className={titleClasses}
              aria-required={required}
              data-cy="pq-select__input-title"
            >
              <span>{caption}</span>
            </span>
          )}
          {renderSelectType()}
        </div>
        <ElementError name={name} errors={errors} />
      </div>
    )
  },
)

export { Select }
