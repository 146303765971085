import { DropdownIndicatorProps, components } from 'react-select'
import { ChevrondownSvg } from '/@icons'

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevrondownSvg width={12} height={12} viewBox={'0 0 12 6'} />
    </components.DropdownIndicator>
  )
}

export default DropdownIndicator
