import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { vars } from '/@theme/theme.css'

/**
 * @todo replace emotion/react w/ vanilla-extract
 */
export const InputWrapper = styled.input`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: solid 2px rgba(203, 208, 223, 0.3);

  &.-error {
    border-color: rgba(255, 105, 109, 0.5);
  }

  outline: 0;
  padding-left: 1.375em;
  font-size: 0.813rem;
  line-height: 1.54;
  color: #0d1331;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }

  &:focus:not(.-error) {
    border: solid 2px #3f6db4;
  }

  &::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: ${vars.color.gray['50']};
  }
  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: ${vars.color.gray['50']};
    opacity: 1;
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: ${vars.color.gray['50']};
    opacity: 1;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${vars.color.gray['50']};
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${vars.color.gray['50']};
  }

  &::placeholder {
    /* Most modern browsers support this now. */
    color: ${vars.color.gray['50']};
  }
`

export const InputOuterWrapperCss = css`
  position: relative;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  background-color: #ffffff;

  &.-disabled {
    opacity: 0.5;
    input {
      background-color: ${vars.color.gray['10']};
    }
  }
`

export const InputTitleWrapperCss = css`
  position: absolute;
  margin-left: 1rem;
  margin-top: -0.625em;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.5;
  color: #8a91a5;
  background-color: inherit;
  white-space: nowrap;
  padding: 0 0.5em;
  opacity: 1;
`

export const InputTitleCss = css`
  &.-required:after {
    content: ' *';
    color: #ff696d;
  }
`
