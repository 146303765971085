import { StylesConfig } from 'react-select'
import { vars } from '/@theme/theme.css'

export const getSelectStyling = (props?: {
  hasTooltip?: boolean
  menuWidth?: string
}): StylesConfig => {
  const commonStyles: StylesConfig = {
    container: (provided) => ({
      ...provided,
      position: 'relative',
      minHeight: '48px',
      width: '100%',
      borderRadius: '8px',
      border: 'solid 0px transparent',
      boxSizing: 'border-box',
      height: '100% !important',
      fontSize: '13px',
      lineHeight: 1.54,
      color: vars.color.black.dark,
    }),
    valueContainer: (provided) => ({
      ...provided,
      marginLeft: '12px',
      // Ensure the nested input is fully visible:
      input: { opacity: 1 },
    }),
    singleValue: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: '100%',
    }),
    control: (provided, state) => {
      let border = 'solid 2px rgba(203,208,223,0.3)'
      let borderColor = provided.borderColor
      let backgroundColor = vars.color.white

      if (state.menuIsOpen || state.isFocused) {
        borderColor = vars.color.blue['40']
        border = `solid 2px ${vars.color.blue['40']}`
      } else if (state.isDisabled) {
        borderColor = vars.color.gray['20']
        backgroundColor = vars.color.gray['10']
      }

      return {
        ...provided,
        height: 'auto',
        minHeight: '48px',
        boxShadow: 'none',
        borderRadius: '8px',
        backgroundColor,
        color: '#0d1331',
        width: '100%',
        flexWrap: 'nowrap',
        border,
        opacity: state.isDisabled ? 0.5 : 1,
        borderColor,
        ':hover': {
          borderColor,
        },
      }
    },
    input: (provided) => ({
      ...provided,
      gridArea: '1/1/2/3',
    }),
    placeholder: (provided, state) => {
      const overFlowTooltipStyles = props?.hasTooltip
        ? {
            display: 'inline-block',
            paddingRight: '15px',
            width: '100%',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }
        : {}
      return {
        ...provided,
        ...overFlowTooltipStyles,
        marginLeft: '2px',
        gridArea: '1/1/2/3',
        fontWeight: 400,
        color: state.isDisabled ? vars.color.gray['40'] : vars.color.gray['60'],
      }
    },
    menu: (provided) => ({
      ...provided,
      zIndex: 10000,
      borderRadius: '8px',
      backgroundColor: 'rgb(255,255,255)',
      boxShadow: '0 2px 16px 0 rgba(203,208,223,0.4)',
    }),
    menuList: (provided) => ({
      ...provided,
      width: props?.menuWidth ?? '100%',
    }),
    option: (provided, state) => {
      const isLast =
        state.selectProps.options.indexOf(state.data) ===
        state.selectProps.options.length - 1

      return {
        ...provided,
        padding: '10px',
        height: '42px',
        border: 'solid 0px rgba(203, 208, 223, 0.3)',
        boxShadow: isLast
          ? 'none'
          : 'inset 0 -1px 0 0 rgba(203, 208, 223, 0.3)',
        position: 'relative',
        lineHeight: 1.54,
        width: props?.menuWidth ?? '100%',
        '&:hover': {
          backgroundColor: 'none',
          color: vars.color.blue['40'],
        },
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        fontSize: '13px',
        fontWeight: 500,
        color:
          state.isSelected || state.isFocused
            ? vars.color.blue['40']
            : vars.color.black.dark,
        backgroundColor: 'transparent',
      }
    },
    dropdownIndicator: (provided) => ({
      ...provided,
      paddingRight: '12px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: 'rgb(153,153,153)',
      fontWeight: 400,
    }),
  }
  return commonStyles
}
