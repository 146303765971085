import { forwardRef } from 'react'
import { RadioProps } from './types'
import {
  checkmark,
  checkmarkChecked,
  radioButtonInput,
  radioButtonLabel,
  radioButtonDisabled,
  radioButtonSvg,
  radioButtonTitle,
  sizeVar,
  checkmarkInner,
  radioWrapper,
} from './radio.css.ts'
import cn from 'classnames'
import { assignInlineVars } from '@vanilla-extract/dynamic'

const Radio = forwardRef<HTMLInputElement, RadioProps>(
  (props, forwardedRef) => {
    const {
      children,
      className,
      checked,
      id,
      size,
      disabled,
      caption,
      checkmarkAsSvg,
      variant,
      ...rest
    } = props

    // Compose the label classes.
    const labelClasses = cn(
      radioButtonLabel,
      {
        [radioButtonDisabled]: disabled,
      },
      className,
    )

    const titleClasses = cn(radioButtonTitle, {
      [radioButtonDisabled]: disabled,
    })

    // converts number size to pixel
    // and sets default value
    const assignSize = (s: number, def = '24px') => {
      return s ? `${s}px` : def
    }

    const renderCheckmark = () => {
      if (!checkmarkAsSvg) {
        return (
          <span
            style={assignInlineVars({
              [sizeVar]: assignSize(size),
            })}
            className={cn(checkmark, {
              [checkmarkChecked]: checked,
            })}
          >
            {checked && (
              <span
                style={assignInlineVars({
                  [sizeVar]: assignSize(size, '16px'),
                })}
                className={checkmarkInner}
              />
            )}
          </span>
        )
      }

      return (
        <span
          style={assignInlineVars({
            [sizeVar]: assignSize(size),
          })}
          className={cn(radioButtonSvg)}
        >
          {checkmarkAsSvg()}
        </span>
      )
    }

    return (
      <div
        className={radioWrapper({
          variant: disabled ? 'default' : variant,
        })}
      >
        <label htmlFor={id} className={labelClasses}>
          {renderCheckmark()}
          {caption && (
            <span
              style={assignInlineVars({
                [sizeVar]: assignSize(size, '16px'),
              })}
              className={titleClasses}
              data-cy="pq-radiobutton__title"
            >
              {caption}
            </span>
          )}
          <input
            id={id}
            type="radio"
            className={radioButtonInput}
            ref={forwardedRef}
            {...rest}
          />
          {children}
        </label>
      </div>
    )
  },
)

export { Radio }
