import 'src/form/radio/radio.css.ts.vanilla.css?source=#H4sIAAAAAAAAA51VTY/bIBC951egXa2USCHC9jrZkFPbVc699ogx2DQYKOBN0qr97ZWd2CGfu1mf7Jk3jzfDw0x+VcVmhmLwZwBALpyRZItBJjVdLQYAECkKBYVnlcOAMuWZbcJrkfsSAy48pFp5pvxi8Hcw2XE9t1wQ7r4iDGyRkWGMknGMXsZxnIzRJBkFBelpwWP8JZ3NvwWQ6fuQ2RkkWU5fvz4HkJczyHI5nU9fA8j8eBBcss2NOdDaOm0xMFrsQj0PaXm6fM44qaVvSrQhVPgtBmiSBvisxXOtPOSkEnKLwXdtjFBu0cWd+M0wiBKz6UNrJorSY6C0rYg8IP1WsgtRyzwtw7hk3jMLXaNJFWGGatkIf0R5lCRRu+el8KyFttRrS0wTNiTPhSqgZNxjkDbi+qZo25TRTnihFQYkc1rWnh3P4eIgASj3vaHAcCggz1vyTNucWWhJLmqHQYqeGnhG6KqwulY57Brh7bPoSzBwWoocxGZzzaAHDW/EDjvboFEg6DzROAa60gq1OtGLS/3GbKA6lBASRaOjMsL9vmx/1jB4eFhcHWzvXaUVC4jYXc7+WTsv+Bb2Sx5S74u/ti13j7MXz+/a7Gt6Os3RRc2fV1fc8LnXple6OyNoMo1TVrVJS5Tj2lZ49yqJZz+GMEVPo9u/F7o3k1Cm9uAfuHAmPuQuirmmtWsYaMnoqiJ2dS8HuU/Le7cB7QhY4PzTm+ls8SOTf3zR/0nZZlz/BgAA';
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var checkmark = 'qmgx70d';
export var checkmarkChecked = 'qmgx70e';
export var checkmarkInner = 'qmgx70f';
export var radioButtonDisabled = 'qmgx70a';
export var radioButtonInput = 'qmgx70c';
export var radioButtonLabel = 'qmgx709';
export var radioButtonSvg = 'qmgx70g';
export var radioButtonTitle = 'qmgx70b';
export var radioButtonWrapper = 'qmgx702';
export var radioColorVar = 'var(--qmgx701)';
export var radioWrapper = _7a468({defaultClassName:'qmgx703 qmgx702',variantClassNames:{variant:{'default':'qmgx704','primary-blue':'qmgx705','primary-red':'qmgx706','secondary-blue':'qmgx707','secondary-red':'qmgx708'}},defaultVariants:{variant:'primary-blue'},compoundVariants:[]});
export var sizeVar = 'var(--qmgx700)';