import * as react from 'react'
import { TextAreaProps } from './types'
import classNames from 'classnames'
import {
  TextAreaBoardCss,
  TextAreaCaptionCss,
  TextAreaCss,
  TextAreaOuterCss,
} from './styles'
import TextareaAutosize from 'react-textarea-autosize'
import { ElementError } from '../error'
import isEmpty from 'lodash/isEmpty'

const TextArea = react.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (props, forwardedRef) => {
    const {
      className,
      caption,
      errors,
      showErrorBoard,
      textAreaOuterCss,
      disabled,
      ...rest
    } = props

    const classes = classNames('pq-form__textarea', className, {
      ['-error']: !isEmpty(errors) && !!errors[rest.name],
      ['-disabled']: disabled,
    })

    const textAreaCaptionClasses = classNames('pq-form__textarea-caption', {
      ['-disabled']: disabled,
    })

    const renderCaption = () => {
      if (!caption) return null

      return (
        <span css={TextAreaCaptionCss} className={textAreaCaptionClasses}>
          <span>{caption}</span>
        </span>
      )
    }

    return (
      <div css={TextAreaOuterCss(textAreaOuterCss)}>
        <div
          className={classNames('pq-form__textarea-board', {
            ['-disabled']: disabled,
          })}
          css={TextAreaBoardCss}
        >
          {renderCaption()}
          <TextareaAutosize
            data-cy="pq-form__textarea"
            className={classes}
            ref={forwardedRef}
            autoComplete="off"
            css={TextAreaCss}
            disabled={disabled}
            {...rest}
          />
        </div>
        <ElementError
          name={rest?.name}
          errors={errors}
          showErrorBoard={showErrorBoard}
        />
      </div>
    )
  },
)

TextArea.displayName = 'Textarea'

export { TextArea }
