import customSingleValue from './single-value-custom'

/**
 * @todo fix issue with popover not working in react-select
 *       the tooltip would be within the react-select container
 *       and not be portaled out
 */
const toolTipSingleValue = (props, selectRef = null) => {
  return customSingleValue(props, selectRef)
}

export default toolTipSingleValue
