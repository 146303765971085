import { forwardRef, useCallback, useState } from 'react'
import { InputGroupProps } from './types'
import { CSSObject, css } from '@emotion/react'
import { getValidChildren } from '/@lib'
import { InputGroupProvider } from './context'

const InputGroup = forwardRef<HTMLDivElement, InputGroupProps>((props, ref) => {
  const { children } = props

  const [hasLeft, setHasLeft] = useState(false)
  const [hasRight, setHasRight] = useState(false)

  const register = useCallback(
    (type: 'InputLeftElement' | 'InputRightElement') => {
      if (type === 'InputLeftElement') setHasLeft(true)
      else if (type === 'InputRightElement') setHasRight(true)
    },
    [],
  )
  const validChildren = getValidChildren(children)

  const cssStyles: CSSObject = {}
  const inputBoardCssStyles: CSSObject = {}

  if (hasLeft) {
    cssStyles.paddingInlineStart = '2.5rem'
  }

  if (hasRight) {
    inputBoardCssStyles.flex = 1
  }

  return (
    <InputGroupProvider
      data={{
        css: css(cssStyles),
        inputBoardCss: css(inputBoardCssStyles),
        register,
      }}
    >
      <div
        ref={ref}
        css={css`
          display: flex;
          width: 100%;
          position: relative;
        `}
      >
        {validChildren}
      </div>
    </InputGroupProvider>
  )
})

InputGroup.displayName = 'InputGroup'

export { InputGroup }
