import { css } from '@emotion/react'
import { vars } from '/@theme/theme.css'

/**
 * @todo replace emotion/react w/ vanilla-extract
 */
export const PhoneNumberInputCss = css`
  position: relative;
  opacity: 1;

  .-disabled {
    span {
      opacity: 0.5;
      background-color: ${vars.color.gray['10']};
    }
  }

  .pq-phone-input__caption {
    position: absolute;
    margin-left: 1rem;
    margin-top: -0.625em;
    font-family: inherit;
    font-size: 0.75rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #8a91a5;
    background-color: white;
    white-space: nowrap;
    z-index: 1;
    padding: 0 0.5em;
  }

  .react-tel-input input {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    border: solid 2px rgba(203, 208, 223, 0.3);
    outline: 0;
    padding-left: 50px;
    font-family: inherit;
    font-size: 13px;
    line-height: 1.54;
    color: #0d1331;

    &:disabled {
      opacity: 0.5;
      cursor: auto;
      background-color: ${vars.color.gray['10']};
    }

    &:hover {
      border: solid 2px rgba(203, 208, 223, 0.3);
    }

    &:focus {
      border: solid 2px #3f6db4;
      box-shadow: none;
    }
  }

  .react-tel-input .flag {
    filter: grayscale(0%);
  }
`
