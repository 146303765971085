import { forwardRef } from 'react'
import PI, { PhoneInputProps } from 'react-phone-input-2'
import { PhoneNumberInputProps } from './types'
import { ElementError } from '../error'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import { PhoneNumberInputCss } from './styles'

const PhoneInput: React.FC<PhoneInputProps> = (PI as any).default || PI
const PhoneNumberInput = forwardRef<HTMLDivElement, PhoneNumberInputProps>(
  (props, ref) => {
    const {
      caption,
      errors,
      showErrorBoard,
      name = '',
      disabled,
      phoneInputProps,
      className,
      required,
    } = props

    const { country, ...rest } = phoneInputProps

    const classes = classNames('pq-form__input', className, {
      ['-error']: !isEmpty(errors) && !!errors[name],
      ['-disabled']: disabled,
    })

    const captionClass = classNames('pq-phone-input__caption', {
      ['-disabled']: disabled,
    })

    return (
      <div className={classes} css={PhoneNumberInputCss}>
        {caption && (
          <span className={captionClass}>
            <span>{caption}</span>
          </span>
        )}
        <PhoneInput
          autocompleteSearch={false}
          disabled={disabled}
          specialLabel=""
          country={country ?? 'gb'}
          inputProps={{
            name,
            ref,
            required,
            autoComplete: 'off',
          }}
          {...rest}
        />
        <ElementError
          errors={errors}
          name={name}
          showErrorBoard={showErrorBoard}
        />
      </div>
    )
  },
)

PhoneNumberInput.displayName = 'PhoneNumberInput'

export { PhoneNumberInput }
