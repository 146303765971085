import { Checkbox } from '/@form/checkbox'

const OptionCustom = (props) => {
  const { getStyles, innerProps, data, innerRef, isMulti } = props

  const inputId = String(data.label).toLowerCase().replace(' ', '_')

  const renderOption = () => {
    if (data.checkbox && isMulti) {
      return (
        <Checkbox
          name={inputId}
          caption={data.label}
          {...innerProps}
          checked={props?.isSelected}
          readOnly
          disabled={props?.isDisabled}
        />
      )
    }

    return <span>{data.label}</span>
  }

  return (
    <div
      {...innerProps}
      ref={innerRef}
      style={getStyles('option', props)}
      className="pq-select__option"
    >
      {data.icon && (
        <span className={`pq-select__option-icon pq-icon -${data.icon}`} />
      )}
      {renderOption()}
    </div>
  )
}

export default OptionCustom
