import classNames from 'classnames'
import { ComponentPropsWithRef, forwardRef } from 'react'
import { Input } from './input'
import { InputGroup } from './input/group'
import { InputLeftElement, InputRightElement } from './input/element'
import { TextArea } from './text-area'
import { Checkbox } from './checkbox'
import { PhoneNumberInput } from './phone-number'
import { FormEditor } from './editor'
import { Radio } from './radio'
import { Select } from './select'

const Form = forwardRef<HTMLFormElement, ComponentPropsWithRef<'form'>>(
  (props, ref) => {
    const { children, className, ...rest } = props
    const classes = classNames('pq-form', className)

    return (
      <form className={classes} ref={ref} {...rest}>
        {children}
      </form>
    )
  },
)

Form.displayName = 'Form'

const FormNamespace = Object.assign(Form, {
  Input,
  InputLeftElement,
  InputRightElement,
  InputGroup,
  TextArea,
  Checkbox,
  PhoneNumber: PhoneNumberInput,
  Editor: FormEditor,
  Radio,
  Select,
})

export { FormNamespace as Form }
